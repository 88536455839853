<template>
  <div class="editDialog">
    <el-button type="text" @click="showEditDialog(roleId)">编辑</el-button>

    <!-- 修改角色的对话框 -->
    <el-dialog
            title="编辑角色"
            :visible.sync="editDialogVisible"
            width="50%"
            @close="rightDialogClose"
            >
      <el-form :model="editRoleForm" :rules="roleFormRules" ref="editRoleForm" label-width="80px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="editRoleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="权限设置" prop="roleAuthority">
          <el-card class="box-card">
          <el-tree
              :data="rightsList"
              :props="treeProps"
              show-checkbox
              node-key="id"
              default-expand-all
              :default-checked-keys="defaultKeys"
              ref="treeRefs"
              ></el-tree>
          </el-card>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

  import {
    getRoleByIdRequest,
    editRoleRequest,
    
  } from 'network/rights'

  export default {
    name: "RoleEdit",
    props: {
      roleId: {
        type: Number,
        required: true
      },
      rightsList: {
        type: Array
      }
    },
    data() {
      return {
        
        defaultKeys:[],
        treeProps: { // 树形控件的对象
          label: 'menuName',
          children: 'children'
        },
        editDialogVisible: false,
        editRoleForm: {
          roleName: '',
          roleDesc: ''
        },
        roleFormRules: {
          roleName: [
            {required: true, message: '请输入角色名称', trigger: 'blur'},
            {min: 2, max: 10, message: '长度需要在2到10之间', trigger: 'blur'}
          ],
          roleDesc: [
            {required: true, message: '请输入角色描述', trigger: 'blur'},
            {min: 3, max: 20, message: '长度需要在3到20之间', trigger: 'blur'}
          ],
        },
      }
    },
    created() {
      
    },
    methods: {
      
      // 展示编辑角色的弹窗
      showEditDialog(id) {
        this.editDialogVisible = true;
        getRoleByIdRequest({id}).then(res => {
          console.log(res);
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取角色信息失败', 'error');
          }

          this.editRoleForm = result.data;
          let dataStrArr=result.data.roleCode.split(",");
          let dataIntArr = []
          dataStrArr.forEach(item => {  
              dataIntArr.push(+item);  
          }); 
          this.defaultKeys = dataIntArr;
        })
      },

      // 点击编辑角色弹框的“确认”按钮提交
      editRole() {
        let keys = [
          ...this.$refs.treeRefs.getCheckedKeys(),
          ...this.$refs.treeRefs.getHalfCheckedKeys()
        ];

        let rids = keys.join(',');
        console.log(rids);
        this.$refs.editRoleForm.validate(valid => {
          if (!valid) return;
          editRoleRequest({
            roleId:this.editRoleForm.id,
            roleName: this.editRoleForm.roleName,
            menuId:rids
          }).then(res => {
            let result = res.data;

            if (result.code !== 200) {
              return this.alertMessage('修改失败', 'error');
            }
            this.alertMessage('修改成功', 'success');
            // 隐藏弹窗
            this.editDialogVisible = false;
            // 重新获取角色列表
            this.$emit('role-list');
          })
        })
      },
      // // 监听添加角色的弹框的关闭事件
      // addDialogClose() {
      //   this.$refs.editRoleForm.resetFields();
      // },
      rightDialogClose() {
        this.defaultKeys = []
      }
    }
  }
</script>

<style scoped>
.editDialog {
  display: inline-block;
  margin-right: 5px;
}
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
