<template>
  <div>
    <!-- 按钮 -->
    <el-row>
      <el-col>
        <el-button type="primary" @click="addDialogVisible = true">添加角色</el-button>
      </el-col>
    </el-row>

    <!-- 添加角色的对话框 -->
    <el-dialog
            title="添加角色"
            :visible.sync="addDialogVisible"
            width="50%"
            @close="rightDialogClose">
      <el-form :model="addRoleForm" :rules="roleFormRules" ref="addRoleForm" label-width="80px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="addRoleForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="权限设置">
          <el-card class="box-card">
          <el-tree
              :data="rightsList"
              :props="treeProps"
              show-checkbox
              node-key="id"
              default-expand-all
              :default-checked-keys="defaultKeys"
              ref="treeRef"></el-tree>
          </el-card>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
  import {getRightsListRequest,addRoleRequest} from 'network/rights'
  export default {
    name: "RoleAdd",
    data() {
      return {
        rightsList: [], // 权限列表数据
        defaultKeys:[],
        treeProps: { // 树形控件的对象
          label: 'menuName',
          children: 'children'
        },
        addDialogVisible:false,
        addRoleForm: {
          roleName: '',
        },
        roleFormRules: {
          roleName: [
            {required: true, message: '请输入角色名称', trigger: 'blur'},
            {min: 2, max: 10, message: '长度需要在2到10之间', trigger: 'blur'}
          ],
        },
      }
    },
    created() {
      this.getRole();
    },
    methods:{
      getRole() {
        // 获取所有权限
        getRightsListRequest({
          enterpriseId: localStorage.getItem('enterpriseId')
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取权限数据失败', 'error');
          }
          this.rightsList = result.data;

          // this.getLeafKeys(role, this.defaultKeys);
        })
      },
      // 添加角色
      addRole() {
        let keys = [
          ...this.$refs.treeRef.getCheckedKeys(),
          ...this.$refs.treeRef.getHalfCheckedKeys()
        ];

        let rids = keys.join(',');
        console.log(rids);
        this.$refs.addRoleForm.validate(valid => {
          if (!valid) return;
          console.log(this.addRoleForm);
          addRoleRequest({
            roleName: this.addRoleForm.roleName,
            roleCode: rids,
            enterpriseId: localStorage.getItem('enterpriseId')
          }).then(res => {
            console.log(res);
            let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage('添加角色失败', 'error');
            }
            this.alertMessage('添加角色成功', 'success');

            // 隐藏弹窗
            this.addDialogVisible = false;

            // 重新获取角色列表
            this.$emit('role-list');
          })
        })
      },

      // 监听添加角色的弹框的关闭事件
      rightDialogClose() {
        this.$refs.addRoleForm.resetFields();
        this.defaultKeys = [];
        
      },
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border: 0px;
    border-color: #fff;
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,.el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }

</style>
