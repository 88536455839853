<!--
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-21 10:37:18
-->
<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>权限管理</template>
        <template v-slot:secondMenu>角色列表</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
          角色列表
      </div>
      <div class="radioBox">
        <!-- 添加角色 -->
        <role-add @role-list="updateRoleList" />
      </div>
      <!-- 角色表格 -->
      <role-table :roles-list="rolesList" @role-list="updateRoleList" :rightsList="rightsList" />
      <div style="float: right;">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pageNumber"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    </el-card>
    
    
  </div>
</template>

<script>

  // 导入公共组件
  import BreadcrumbNav from "../common/BreadcrumbNav";

  // 导入子组件
  import RoleAdd from "./rolesChildComponents/RoleAdd";
  import RoleEdit from "./rolesChildComponents/RoleEdit";
  import RoleRemove from "./rolesChildComponents/RoleRemove";
  import RoleSetRight from "./rolesChildComponents/RoleSetRight";
  import RoleTable from "./rolesChildComponents/RoleTable";

  // 导入发送网络请求的函数
  import {getRolesListRequest,getRightsListRequest} from 'network/rights'

  export default {
    name: "Roles",
    components:{
      BreadcrumbNav,
      RoleAdd,
      RoleEdit,
      RoleRemove,
      RoleSetRight,
      RoleTable
    },
    data() {
      return {
        rolesList: [],
        queryInfo: {
          pageNumber: 1,
          pageSize: 10
        },
        total: 0,
        rightsList: [], // 权限列表数据
      }
    },
    created() {
      this.getRolesList();
      this.getRole();
    },
    methods: {
      getRole() {
        // 获取所有权限
        getRightsListRequest({
          enterpriseId: localStorage.getItem('enterpriseId')
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取权限数据失败', 'error');
          }
          this.rightsList = result.data;
        })
      },
      // 获取角色列表
      getRolesList() {
        getRolesListRequest({
          ...this.queryInfo
        }).then(res => {
          console.log(res)
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取角色列表失败', 'error');
          }
          this.rolesList = result.data.list;
          this.total = result.data.total;
        })
      },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNumber = 1
        this.queryInfo.pageSize = newSize;
        this.getRolesList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNumber = newPage;
        this.getRolesList();
      },

      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList(){
        this.getRolesList();
      }
    }
  }
</script>

<style scoped>
.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}

</style>
