<!--
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-17 15:20:10
-->
<template>
  <el-table highlight-current-row :data="rolesList" border :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}" :cell-style="{color: '#0a0b1a'}">
    <el-table-column prop="roleName" label="角色名称"></el-table-column>
    <el-table-column prop="createDate" label="创建时间" :formatter="formatData">
      <!-- <template slot-scope="scope">
          {{scope.row.createDate | timestampToTime}}
        </template> -->
    </el-table-column>
    <el-table-column label="操作" width="300px">
      <template slot-scope="scope">
        <!-- 编辑按钮 -->
        <role-edit :role-id="scope.row.id" @role-list="updateRoleList" :rightsList="rightsList"/>

        <!-- 删除按钮 -->
        <role-remove :role-id="scope.row.id" @role-list="updateRoleList"/>

        <!-- 分配权限的按钮 -->
        <!-- <role-set-right :role="scope.row" @role-list="updateRoleList"/> -->
      </template>
    </el-table-column>
  </el-table>
  
</template>

<script>
  import RoleEdit from "./RoleEdit";
  import RoleRemove from "./RoleRemove";
  import RoleSetRight from "./RoleSetRight";
  import RoleTableRightsRow from "./RoleTableRightsRow";

  export default {
    name: "RoleTable",
    props: {
      rolesList: {
        type: Array,
        default() {
          return []
        }
      },
      rightsList: {
        type: Array
      }
    },
    components: {
      RoleEdit,
      RoleRemove,
      RoleSetRight,
      RoleTableRightsRow
    },
    methods: {
      formatData(row, column, cellValue, index){
        var s =	new Date(cellValue);
        var date = new Date(s);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
          var h = date.getHours();
        var minute = date.getMinutes();
        minute = minute < 10 ? ('0' + minute) : minute;
        let time = y + '-' + m + '-' + d+' '+h+':'+minute;
        return time;
	    },

      // 监听子组件中发出的事件，重新获取角色列表
      updateRoleList() {
        this.$emit('role-list');
      }
    }
  }
</script>

<style scoped>

</style>
